/*eslint @typescript-eslint/no-unused-vars: "off" */
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Link as MuiLink } from '@mui/material';
import { underscore, titleize } from 'inflection';
import { FC, useCallback, useEffect, useState } from 'react';
import { BooleanField, Create, CreateProps, Datagrid, Edit, EditProps, email, FunctionField, ImageField, List, ListProps, RaRecord, Show, ShowProps, SimpleFormProps, SortPayload, Tab, TextField, TextInput, useRecordContext } from 'react-admin';
import { AdminSimpleForm, AdminTabbedShowLayout, useAdminListAppLocation } from './Admin';
import { apiUrl, httpClient } from './DataProvider';
import { TimestampsField } from './TimestampsField';

export { Person as UserIcon } from '@mui/icons-material';

export const UserList: FC<ListProps> = props => {
  useAdminListAppLocation();

  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid rowClick="show"
        bulkActionButtons={ false }
        sx={ {
          '& .column-pictureUrl .RaImageField-image': {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
          }

        } }
      >
        <ImageField label='Avatar' source='pictureUrl' />
        <TextField source="name" />
        <TextField source="email" />
        <FunctionField label='Blocked' render={ ( r: RaRecord ) => (
          <BooleanField
            record={ { blocked: r.roles.length === 0 } }
            source='blocked' valueLabelTrue='Blocked' valueLabelFalse='Not blocked' TrueIcon={ CheckBox } FalseIcon={ CheckBoxOutlineBlank } />
        ) } />
        <TextField label='Logins' source="loginsCount" />
        <TextField label="Last IP" source="lastIp" />
        <TimestampsField label="Last login" source="lastLogin" />
        <TimestampsField label="Updated" source="updatedAt" />

        { /* <FunctionField label='Provider' render={ ( r: RaRecord ) => {
          const [ extId = {} ] = r?.externalIds || [];
          const { type } = extId || {}
          if( !type ) return <></>;
          return type.includes( 'auth' ) ? 'auth0' : type.includes( 'supertokens' ) ? type : 'unknown';
        } } /> */ }
      </Datagrid>
    </List>
  );
}

export const UserActivityLog: FC = () => {
  const record = useRecordContext();
  const { id } = record || {};
  const [ activity, setActivity ] = useState<Array<RaRecord>>( [] );
  const [ isLoading, setIsLoading ] = useState( false );

  const fetchActivity = useCallback( async ( id: string ) => {
    setIsLoading( true );
    const { body } = await httpClient( `${ apiUrl }/users/${ id }/actions/getLogs` );
    setIsLoading( false );
    return body;
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      try {
        if( typeof id != 'string' ) return;
        const body = await fetchActivity( id );
        setActivity( JSON.parse( body ) );
      } catch( e ) {
        setActivity( [] );
      }
    } )()
  }, [ setActivity, fetchActivity, id ] );

  const sort: SortPayload = { field: 'createdAt', order: 'DESC' };

  const prettyAction = ( s?: string ): string => !s ? '' : titleize( underscore( s ) );
  const prettyStatus = ( s?: string ): string => !s ? '' : s.match( /^[A-Z]+$/ ) ? s : titleize( s );


  return (

    <Datagrid
      data={ activity }
      total={ activity.length }
      isLoading={ isLoading }
      sort={ sort }
      bulkActionButtons={ false }
    >
      <TimestampsField label='Date' source='createdAt' />
      <FunctionField label='Event' source='action' render={ ( r: RaRecord ) => prettyAction( r?.action ) } />
      <FunctionField label='Status' source='status' render={ ( r: RaRecord ) => prettyStatus( r?.status ) } />
      <FunctionField label='LatLng' source='lat'
        render={ ( r: RaRecord ) => {
          const fix = ( s: string ): string => parseFloat( s ).toFixed( 2 );
          return r && r.lat && r.lng
            ? (
              <MuiLink
                href={ `https://www.google.com/maps/@${ r.lat },${ r.lng },15z?entry=ttu` }
                underline='none'
                target="_blank"
                rel="noreferrer"
              >
                { `${ fix( r.lat ) },${ fix( r.lng ) }` }
              </MuiLink>
            )
            : ''
        } }
      />
      <TextField label='IP Address' source='ipAddress' />
      <TextField label='Browser' source='userAgent' />
      <TextField label='Method' source='authMethod' />
      <TextField label='App' source='appName' />
    </Datagrid>
  );
}

export const UserShow: FC<ShowProps> = () => {
  return (
    <Show >
      <AdminTabbedShowLayout basePath='organization'      >
        <Tab label="User Detail"        >
          <TextField source="name" fullWidth />
          <TextField source="nickname" />
          <TextField source="email" fullWidth />
          <FunctionField label='Blocked' render={ ( r: RaRecord ) => (
            <BooleanField
              record={ { blocked: r.roles.length === 0 } }
              source='blocked' valueLabelTrue='Blocked' valueLabelFalse='Not blocked' TrueIcon={ CheckBox } FalseIcon={ CheckBoxOutlineBlank } />
          ) } />
          <TextField label='Logins' source="loginsCount" />
          <TimestampsField label="Last login" source="lastLogin" />
          <ImageField label='Avatar' source='pictureUrl'
            sx={ {
              '& .RaImageField-image': {
                width: '80px',
                height: '80px',
                borderRadius: '50%',
              }
            } }
          />
          <TimestampsField label="Updated" source="updatedAt" />
          <TimestampsField label="Created" source="createdAt" />
        </Tab>
        <Tab label="Recent Activity">
          <UserActivityLog />
        </Tab>
      </AdminTabbedShowLayout>
    </Show>
  );
}


const UserForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const record = useRecordContext();
  return (
    <AdminSimpleForm sanitizeEmptyValues  { ...props } >

      <TextInput source="name" fullWidth required />
      <TextInput source="nickname" fullWidth />
      { !record?.id
        ? <TextInput source="email" fullWidth validate={ email() } required />
        : <></>
      }
    </AdminSimpleForm>
  );
}

export const UserCreate: FC<CreateProps> = props => ( <Create redirect='show' { ...props } ><UserForm /></Create> );
export const UserEdit: FC<EditProps> = props => ( <Edit redirect='list'  { ...props } mutationMode='pessimistic' ><UserForm /></Edit> );

